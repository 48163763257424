.benefits{
    background-image: linear-gradient(to right,#ff2526,#111);
    padding:2rem;
}
.benefits img{
    width:400px;
}
.the-lists{
    color:white;
}
   
.benefits h2{
    color:white;
}
.what-you-get{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.benefits h1{
    text-align: center;
    color:white;
    font-size:55px;
}
@media(max-width:676px){
    .benefits img{
        width:400px;
    }
    .what-you-get{
        flex-direction: column;
    }
    .benefits h1{
        text-align: center;
        color:white;
        font-size:35px;
    }
    .lists h2{
        color:white;
    }
}
@media(max-width:450px){
    .benefits img{
        width:300px;
    }
    .what-you-get{
        flex-direction: column;
    }
    .benefits h1{
        text-align: center;
        color:white;
        font-size:30px;
    }
    .lists h2{
        color:white;
        font-weight: bold;
    }
}
@media(max-width:394px){
    .benefits h1{
        text-align: center;
        color:white;
        font-size:25px;
    } 
}