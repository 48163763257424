
.vision-plus-strategy{
    display:flex;
    justify-content: space-around;
    align-items: center;
    right:0;
}
.vision-plus-strategy img{
    width:200px;
    margin-left:200px;
  
}
.our-vision h1{
    margin:4px;
    border-bottom: 6px solid #ff2526;
    width:250px;
}
.vision-plus-strategy h3{
    text-align: center;
    color:#111;
    font-weight: bold;
    font-size:25px;
}
.vision-plus-strategy p{
    color:#111;
    padding:10px;
    width:90%;
   
   
}

.objective-plus-strategy{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.objective-plus-strategy img{
    width: 200px;
    margin-left:200px;
}
.objective-plus-strategy h3{
    text-align: center;
    color:#111;
    font-weight: bold;
    font-size:25px;
}
.objective-plus-strategy p{
    color:#111;
    padding:10px;
    width:90%;
  
}
@media(max-width:900px){

.vision-plus-strategy{
 flex-direction: column;
}
.vision-plus-strategy img{
    width:400px;
    margin-left:30px;
  
}
.our-vision h1{
    margin:4px;
    border-bottom: 6px solid #ff2526;
    width:250px;
}
.vision-plus-strategy h3{
    text-align: center;
    color:#111;
    font-weight: bold;
    font-size:25px;
}
.vision-plus-strategy p{
    color:#111;
    padding:6px;
    width:100%;
   
   
}

.objective-plus-strategy{
  flex-direction: column;
}
.objective-plus-strategy img{
    width: 400px;
    margin-left:30px;
}
.objective-plus-strategy h3{
    text-align: center;
    color:#111;
    font-weight: bold;
    font-size:25px;
}
.objective-plus-strategy p{
    color:#111;
    padding:6px;
    width:100%;
  
}
}
@media(max-width:600px){

    .vision-plus-strategy{
     flex-direction: column;
    }
    .vision-plus-strategy img{
        width:400px;
        margin-left:30px;
      
    }
    .our-vision h1{
        margin:4px;
        border-bottom: 6px solid #ff2526;
        width:250px;
    }
    .vision-plus-strategy h3{
        text-align: center;
        color:#111;
        font-weight: bold;
        font-size:25px;
    }
    .vision-plus-strategy p{
        color:#111;
        padding:6px;
        width:100%;
       
       
    }
    
    .objective-plus-strategy{
      flex-direction: column;
    }
    .objective-plus-strategy img{
        width: 400px;
        margin-left:30px;
    }
    .objective-plus-strategy h3{
        text-align: center;
        color:#111;
        font-weight: bold;
        font-size:25px;
    }
    .objective-plus-strategy p{
        color:#111;
        padding:6px;
        width:100%;
      
    }
    }
    @media(max-width:500px){

        .vision-plus-strategy{
         flex-direction: column;
        }
        .vision-plus-strategy img{
            width:300px;
            margin-left:70px;
          
        }
        .our-vision h1{
            margin:4px;
            border-bottom: 6px solid #ff2526;
            width:250px;
        }
        .vision-plus-strategy h3{
            text-align: center;
            color:#111;
            font-weight: bold;
            font-size:25px;
        }
        .vision-plus-strategy p{
            color:#111;
            padding:6px;
            width:100%;
           
           
        }
        
        .objective-plus-strategy{
          flex-direction: column;
        }
        .objective-plus-strategy img{
            width: 300px;
            margin-left:70px;
        }
        .objective-plus-strategy h3{
            text-align: center;
            color:#111;
            font-weight: bold;
            font-size:25px;
        }
        .objective-plus-strategy p{
            color:#111;
            padding:6px;
            width:100%;
          
        }
        }

        @media(max-width:400px){

            .vision-plus-strategy{
             flex-direction: column;
            }
            .vision-plus-strategy img{
                width:270px;
                margin-left:50px;
              
            }
            .our-vision h1{
                margin:4px;
                border-bottom: 6px solid #ff2526;
                width:250px;
            }
            .vision-plus-strategy h3{
                text-align: center;
                color:#111;
                font-weight: bold;
                font-size:25px;
            }
            .vision-plus-strategy p{
                color:#111;
                padding:6px;
                width:100%;
               
               
            }
            
            .objective-plus-strategy{
              flex-direction: column;
            }
            .objective-plus-strategy img{
                width: 270px;
                margin-left:50px;
            }
            .objective-plus-strategy h3{
                text-align: center;
                color:#111;
                font-weight: bold;
                font-size:25px;
            }
            .objective-plus-strategy p{
                color:#111;
                padding:6px;
                width:100%;
              
  }
    }

    @media(max-width:354px){

        .vision-plus-strategy{
         flex-direction: column;
        }
        .vision-plus-strategy img{
            width:250px;
            margin-left:30px;
          
        }
        .our-vision h1{
            margin:4px;
            border-bottom: 6px solid #ff2526;
            width:250px;
        }
        .vision-plus-strategy h3{
            text-align: center;
            color:#111;
            font-weight: bold;
            font-size:25px;
        }
        .vision-plus-strategy p{
            color:#111;
            padding:6px;
            width:100%;
           
           
        }
        
        .objective-plus-strategy{
          flex-direction: column;
        }
        .objective-plus-strategy img{
            width: 250px;
            margin-left:30px;
        }
        .objective-plus-strategy h3{
            text-align: center;
            color:#111;
            font-weight: bold;
            font-size:25px;
        }
        .objective-plus-strategy p{
            color:#111;
            padding:6px;
            width:100%;
          
}
}