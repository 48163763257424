.indications{
    padding:2rem;
    margin-left:300px;
}

.indications-process{
    display:flex;
   
}

.circle-one{
    color:white;
    width: 90px;
    height:90px;
    background-color: #ff2526;
    border-radius: 50%;
    text-align: center;
    padding:6px;
    font-size: 35px;;
   
    margin-left:10px;
    
}
.vl{
    border-left: 6px solid #ff2526;
    height:110px;
    margin-left:50px;
}
.process-text-one{
    margin-top:10px;
    margin-left:10px;
}
.process-text-one p{
    width:60%;
    padding:6px;
}
.process-text-one h3{
    font-size:35px;
}
.process-text-two{
    margin-top:10px;
    margin-left:10px; 
}
.process-text-two p{
    width:60%;
    padding:6px;
}
.process-text-two h3{
    font-size:35px;
}
.process-text-three{
    margin-top:15px;
    margin-left:10px;  
}
.process-text-three p{
    width:60%;
    padding:6px;
}
.process-text-three h3{
    font-size:35px;
}
.process-text-four{
    margin-top:15px;
    margin-left:10px;  
}
.process-text-four p{
    width:60%;
    padding:6px;
}
.process-text-four h3{
    font-size:35px;
}
@media(max-width:1029px){
    .indications{
        padding:2rem;
        margin-left:230px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:160px;
        margin-left:50px;
    }
}
@media(max-width:963px){
    .indications{
        padding:2rem;
        margin-left:200px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:160px;
        margin-left:50px;
    }
    .process-text-one{
        margin-top:20px;
        margin-left:10px;
    }
}
@media(max-width:935px){
    .indications{
        padding:1rem;
        margin-left:200px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:160px;
        margin-left:50px;
    }
    .process-text-one{
        margin-top:20px;
        margin-left:10px;
    }
}
@media(max-width:928px){
    .indications{
        padding:1rem;
        margin-left:200px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:160px;
        margin-left:50px;
    }
    .process-text-one{
        margin-top:20px;
        margin-left:10px;
    }
}
@media(max-width:897px){
    .indications{
        padding:0;
        margin-left:170px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:60%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:60%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:60%;
        padding:2px;
    }
    .process-text-four p{
        width:60%;
        padding:2px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:140px;
        margin-left:50px;
    }
}

@media(max-width:854px){
    .indications{
        padding:0;
        margin-left:140px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:140px;
        margin-left:50px;
    }
}
@media(max-width:762px){
    .indications{
        padding:0;
        margin-left:100px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:140px;
        margin-left:50px;
    }
}

@media(max-width:662px){
    .indications{
        padding:0;
        margin-left:70px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:140px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 90px;
        height:90px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 35px;;
       
        margin-left:10px;
        
    }
}


@media(max-width:640px){
    .indications{
        padding:0;
        margin-left:70px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:140px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 90px;
        height:90px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 35px;;
       
        margin-left:10px;
        
    }
}
@media(max-width:632px){
    .indications{
        padding:0;
        margin-left:50px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:140px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 90px;
        height:90px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 35px;;
       
        margin-left:10px;
        
    }
}

@media(max-width:610px){
    .indications{
        padding:0;
        margin-left:30px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 6px solid #ff2526;
        height:140px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 90px;
        height:90px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 35px;;
       
        margin-left:10px;
        
    }
}

@media(max-width:591px){
    .indications{
        padding:1rem;
        margin-left:40px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:160px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 80px;
        height:60px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 25px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:559px){
    .indications{
       padding: 1rem;
        margin-left:40px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:160px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 80px;
        height:60px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 25px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}


@media(max-width:554px){
    .indications{
        padding:1.5rem;
        margin-left:40px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:200px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 80px;
        height:60px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 25px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:543px){
    .indications{
       padding: 2rem;
        margin-left:10px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:100%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:100%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:100%;
        padding:2px;
    }
    .process-text-four p{
        width:100%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:180px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 80px;
        height:60px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 25px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}

@media(max-width:526px){
    .indications{
        padding:0;
        margin-left:40px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:200px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 80px;
        height:60px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 25px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:488px){
    .indications{
        padding:0;
        margin-left:40px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:200px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 80px;
        height:60px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 25px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:482px){
    .indications{
        padding:0;
        margin-left:40px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:80%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:80%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:80%;
        padding:2px;
    }
    .process-text-four p{
        width:80%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:220px;
        margin-left:50px;
    }
    .circle-one{
        color:white;
        width: 80px;
        height:60px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 25px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:450px){
    .indications{
    padding:2rem;
        margin-left:0px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:100%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:100%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:100%;
        padding:2px;
    }
    .process-text-four p{
        width:100%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:220px;
        margin-left:30px;
    }
    .circle-one{
        color:white;
        width: 40px;
        height:40px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 20px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:439px){
    .indications{
       padding: 2rem;
        margin-left:0px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:100%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:100%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:100%;
        padding:2px;
    }
    .process-text-four p{
        width:100%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:220px;
        margin-left:30px;
    }
    .circle-one{
        color:white;
        width: 40px;
        height:40px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 20px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:400px){
    .indications{
       padding: 0.7rem;
        margin-left:0px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:100%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:100%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:100%;
        padding:2px;
    }
    .process-text-four p{
        width:100%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:220px;
        margin-left:30px;
    }
    .circle-one{
        color:white;
        width: 40px;
        height:40px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 20px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:388px){
    .indications{
       padding: 0.5rem;
        margin-left:0px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:100%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:100%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:100%;
        padding:2px;
    }
    .process-text-four p{
        width:100%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:230px;
        margin-left:30px;
    }
    .circle-one{
        color:white;
        width: 40px;
        height:40px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 20px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}

@media(max-width:350px){
    .indications{
       padding-top:0;
        margin-left:0px;
    }
    
    .process-text-one{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-one p{
        width:100%;
        padding:2px;
    }
    process-text-two{
        margin-top:10px;
        margin-left:10px;
    }
    .process-text-two p{
        width:100%;
        padding:2px;
    }
    .process-text-three{
        margin-top:15px;
        margin-left:10px;  
    }
    .process-text-three p{
        width:100%;
        padding:2px;
    }
    .process-text-four p{
        width:100%;
        padding:2px;
    }
    .vl{
        border-left: 4px solid #ff2526;
        height:260px;
        margin-left:30px;
    }
    .circle-one{
        color:white;
        width: 40px;
        height:40px;
        background-color: #ff2526;
        border-radius: 50%;
        text-align: center;
        padding:6px;
        font-size: 20px;;
       
        margin-left:10px;
        
    }
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:25px;
    }
}
@media(max-width:336px){
    .process-text-one h3,
    .process-text-two h3,
    .process-text-three h3,
    .process-text-four h3{
        font-size:22px;
    } 
}