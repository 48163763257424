.view-section{
    background-image: url(../assets/city-4667143_1280.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:100Vh;
    background-color: grey;
    background-blend-mode: multiply;
 
    
    
}

.view-both-text-image{
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:3rem;

}
.text-plus-buttons p{
    
   color:white;
    padding:5px;


    font-size:18px;
   
    width:70%;
  
    
}

.text-plus-buttons h1{
    color:white;
    font-size:60px;

}
.text-plus-buttons h4{
    color:white;
    font-size:23px;
    background-color: #ff2526;
    padding:5px;
    width:500px;
}
.text-plus-buttons{
    margin-left:2.2rem;
}
.view-both-text-image img{
    width:400px;
}
.view-section  .appoint{
    width:200px;
    height:40px;
    background-color: #ff2526;
    padding:10px;
    color:white;
    margin:10px;
    border-radius: 8px;
    outline:none;
    border-style: none;
}
.view-section .appoint:hover{
    background: #111;
}
.view-section  .appoint-2{
    width:400px;
    height:40px;
    background-color: #ff2526;
    padding:10px;
    color:white;
  
    border-radius: 8px;
    outline:none;
    border-style: none;
}
.view-section .appoint-2:hover{
    background-image: linear-gradient(to right, #ff2526,#ffff);
}
.view-section  .fb{
    width:200px;
    height:40px;
    background-color: white;
    padding:10px;
    color:#111;
    margin:10px;
    border-radius: 8px;
    outline:none;
    border-style: none;
}
.view-section .fb:hover{
    background: #ff2526;
}
.view-section img{
   animation: spin 5s  infinite ;
   transition: ease-in-out;
}
.fern{
    color:white;
    border-bottom: 6px solid #ff2526;
}
@keyframes spin{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
@media(max-width:1029px){
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
      
     
         font-size:18px;
        
         width:100%;
       
         
     }
}
@media(max-width:962px){
    .view-both-text-image{
       flex-direction: column;
       flex-wrap: wrap;
        padding-left:3rem;
    
    }
    .view-section{
        height:150Vh;
       
    }
    .view-both-text-image{
        margin-left:50px;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
       
     
         font-size:18px;
        
         width:90%;
       
         
     }
}
@media(max-width:772px){
    .view-both-text-image{
        margin-left:30px;
    }
    .view-section{
        height:160Vh;
       
    }
    .image{
        margin-left:80px;
    }
}
@media(max-width:750px){
    .view-both-text-image{
        margin-left:30px;
    }
    .view-section{
        height:160Vh;
       
    }
}
@media(max-width:712px){
    .text-plus-buttons{
        margin-left:0.5rem;
    }
    .view-section{
        height:160Vh;
       
    }
}
@media(max-width:675px){
    .text-plus-buttons{
        margin-left:0;
    }
    .view-section{
        height:160Vh;
       
    }
}
@media(max-width:650px){
    .text-plus-buttons h1{
        color:white;
        font-size:50px;
    
    }
    .view-section{
        height:160Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:20px;
        background-color: #ff2526;
        padding:5px;
        width:400px;
    }
    .view-both-text-image{
        margin-left:20px;
    }
    .image{
        margin-left:40px;
    }
}

@media(max-width:595px){
    .text-plus-buttons h1{
        color:white;
        font-size:50px;
    
    }
    .view-section{
        height:160Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:20px;
        background-color: #ff2526;
        padding:5px;
        width:400px;
    }
    .view-both-text-image{
        margin-left:10px;
    }
    .image{
        margin-left:15px;
    }
}


@media(max-width:560px){
    .text-plus-buttons h1{
        color:white;
        font-size:40px;
    
    }
    .view-section{
        height:165Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:20px;
        background-color: #ff2526;
        padding:5px;
        width:350px;
    }
    .view-both-text-image{
        margin-left:6px;
    }
    .image{
        margin-left:10px;
    }
    .view-section  .appoint{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:360px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
      
     
         font-size:18px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
      
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
}

@media(max-width:550px){
    .text-plus-buttons h1{
        color:white;
        font-size:40px;
    
    }
    .view-section{
        height:165Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:20px;
        background-color: #ff2526;
        padding:5px;
        width:350px;
    }
    .view-both-text-image{
        margin-left:6px;
    }
    .image{
        margin-left:10px;
    }
    .view-section  .appoint{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:360px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
      
     
         font-size:18px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
      
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
}

@media(max-width:540px){
    .text-plus-buttons h1{
        color:white;
        font-size:40px;
    
    }
    .view-section{
        height:165Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:20px;
        background-color: #ff2526;
        padding:5px;
        width:350px;
    }
    .view-both-text-image{
        margin-left:6px;
    }
    .image{
        margin-left:10px;
    }
    .view-section  .appoint{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:360px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
      
     
         font-size:18px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
      
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
}
@media(max-width:530px){
    .text-plus-buttons h1{
        color:white;
        font-size:40px;
    
    }
    .view-section{
        height:165Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:20px;
        background-color: #ff2526;
        padding:5px;
        width:350px;
    }
    .view-both-text-image{
        margin-left:6px;
    }
    .image{
        margin-left:10px;
    }
    .view-section  .appoint{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:360px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
      
     
         font-size:18px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
      
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
}
@media(max-width:500px){
    .text-plus-buttons h1{
        color:white;
        font-size:40px;
    
    }
    .view-section{
        height:160Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:20px;
        background-color: #ff2526;
        padding:5px;
        width:350px;
    }
    .view-both-text-image{
        margin-left:4px;
    }
    .image{
        margin-left:6px;
    }
    .view-section  .appoint{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:360px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
     
     
         font-size:18px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:360px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
      
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
}
@media(max-width:475px){
    .view-both-text-image{
        margin-left:2px;
        
    }
    .view-section{
        height:160Vh;
       
    }
    .text-plus-buttons h1{
        color:white;
        font-size:35px;
    
    }
    .text-plus-buttons h4{
        color:white;
        font-size:18px;
        background-color: #ff2526;
        padding:5px;
        width:300px;
    }
    
    .image{
        margin-left:6px;
    }
    .view-section  .appoint{
        width:300px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:300px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
        
     
         font-size:18px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:330px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
      
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .image img{
        width:300px;
    }
}

@media(max-width:420px){
    .view-both-text-image{
        margin-left:0;
        
    }
    .text-plus-buttons h1{
        color:white;
        font-size:35px;
    
    }
    .view-section{
        height:160Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:18px;
        background-color: #ff2526;
        padding:5px;
        width:250px;
    }
    
    .image{
        margin-left:6px;
    }
    .view-section  .appoint{
        width:250px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:250px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
       
     
         font-size:18px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:300px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
      
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .image img{
        width:300px;
    }
}


@media(max-width:397px){
    .view-both-text-image{
        margin-right:30px;
        
    }
    .text-plus-buttons h1{
        color:white;
        font-size:35px;
        width:300px;
    
    }
    .view-section{
        height:160Vh;
       
    }
    .text-plus-buttons h4{
        color:white;
        font-size:18px;
        background-color: #ff2526;
        padding:5px;
        width:250px;
    }
    
    .image{
        margin-left:0.2em;
    }
    .view-section  .appoint{
        width:250px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:250px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
         
        margin-right:100px;
         font-size:18px;
        
         width:95%;
       
         
     }
     .view-section  .appoint-2{
        width:250px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
       
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .image img{
        width:300px;
    }
}


@media(max-width:360px){
    .view-both-text-image{
        margin-left:3px;
        
    }
    .view-section{
        height:150Vh;
       
    }
    .text-plus-buttons h1{
        color:white;
        font-size:30px;
        width:300px;
    
    }
    .text-plus-buttons h4{
        color:white;
        font-size:18px;
        background-color: #ff2526;
        padding:5px;
        width:250px;
    }
    
    .image{
        margin-right:10px;
    }
    .view-section  .appoint{
        width:250px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:250px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
         
        margin-right:100px;
         font-size:17px;
        
         width:90%;
       
         
     }
     .view-section  .appoint-2{
        width:250px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
       
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .image img{
        width:200px;
        margin-right:10px;
    }
}
@media(max-width:340px){
  
    .text-plus-buttons{
        margin-left:-40px;
    }
    .text-plus-buttons h1{
        color:white;
        font-size:30px;
        width:300px;
    
    }
    .text-plus-buttons h4{
        color:white;
        font-size:18px;
        background-color: #ff2526;
        padding:5px;
        width:250px;
    }
    
    .image{
        margin-left:-25px;
    }
    .view-section  .appoint{
        width:250px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
        text-align: center;
    }
    .view-section  .fb{
        width:250px;
        height:40px;
        background-color: white;
        padding:10px;
        color:#111;
        text-align: center;
        margin:10px;
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .text-plus-buttons p{
    
        color:white;
         padding:10px;
      
    
         font-size:17px;
        
         width:100%;
       
         
     }
     .view-section  .appoint-2{
        width:250px;
        height:40px;
        background-color: #ff2526;
        padding:10px;
        color:white;
       
        border-radius: 8px;
        outline:none;
        border-style: none;
    }
    .image img{
        width:250px;
        
    }
}