 .flex-tab{
     background-color: white;
 }
 
 .flex-tab p{
    color:#111;
    padding:6px;
   
}
.flex-tab h4{
    color:#111;
    text-align: center;
    font-size:25px;
}
.flex-tab h1{
    text-align: center;
    padding:20px;
    color:#111;
}
.underLiner{
    color:#111;
    border-bottom: 6px solid #ff2526;
}
.tab-images{
    display:flex;
    justify-content: space-around;
    align-items: center;
   background-color: white;
    
}

.tab-images-two{
    display:flex;
    justify-content: space-around;
    align-items: center;
   background-color: white;
     
}
.tab-images img{
    width:100px;
    margin:10px;
    
}
.tab-images-two img{
    width:100px;
    margin:10px;
    
}

.tabs-one{
    display:flex;
}
.tabs-text-one{
    margin-top:3rem;
}
.tabs-two{
    display:flex;
}
.tabs-three{
    display:flex;
}
.tabs-four{
    display:flex;
}
@media(max-width:950px){
    .underLiner{
        color:#111;
        border-bottom: 6px solid #ff2526;
    }
    .tab-images{
        flex-direction: column;
        
    }
    
    .tab-images-two{
       flex-direction: column;
         
    }
    .tab-images img{
        width:300px;
        margin:10px;
        
    }
    .tab-images-two img{
        width:300px;
        margin:10px;
        
    }
}
@media(max-width:660px){
    .underLiner{
        color:#111;
        border-bottom: 6px solid #ff2526;
    }
    .tab-images{
        flex-direction: column;
        
    }
    
    .tab-images-two{
       flex-direction: column;
         
    }
    .tab-images img{
        width:150px;
        margin:10px;
        
    }
    .tab-images-two img{
        width:150px;
        margin:10px;
        
    }
}

@media(max-width:460px){
    .underLiner{
        color:#111;
        border-bottom: 6px solid #ff2526;
    }
    .tab-images{
        flex-direction: column;
        
    }
    
    .tab-images-two{
       flex-direction: column;
         
    }
    .tab-images img{
        width:80px;
        margin:10px;
        
    }
    .tab-images-two img{
        width:80px;
        margin:10px;
        
    }
}