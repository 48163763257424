.footer-main {
    padding: 2rem;
    margin-top: 4rem;
    width: 100%;
    background-color: #111;
    
}

.footer-contents-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.footer-contents-main img {
    width: 250px;
    height: 250px;
    margin: 6px;
    border-radius: 35%;
}

.lists a {
    text-decoration: none;
}

.footer-contents-main h3 {
    color: whitesmoke;
    font-size: 23px;
}

.lists-for-footer ul {
    font-size: 20px;
    list-style-type: none;
    padding: 5px;
}

.lists-for-footer li {
    font-size: 20px;
    color: white
}

.footer-contents-main li a {
    color: white;
}

.footer-main h1 {
    border-bottom: 4px solid white;
}

@media(max-width:989px) {
    .footer-contents-main {
        margin-right: 500px;
        ;
        flex-direction: column;
    }

    .footer-contents-main img {
        width: 350px;
        height: 350px;
        margin: 6px;
        border-radius: 35%;
    }

    .lists-for-footer h3 {
        text-align: center;
    }

    .lists-for-footer li {
        text-align: center;
        margin: 20px;
    }
}

@media(max-width:894px) {
    .footer-contents-main {
        margin-right: 400px;
        ;
        flex-direction: column;
    }

    .footer-contents-main img {
        width: 350px;
        height: 350px;
        margin: 6px;
        border-radius: 35%;
    }

    .lists-for-footer h3 {
        text-align: center;
    }

    .lists-for-footer li {
        text-align: center;
        margin: 10px;
    }
}

@media(max-width:787px) {
    .footer-contents-main {
        margin-right: 300px;
        ;
        flex-direction: column;
    }

    .footer-contents-main img {
        width: 300px;
        height: 300px;
        margin: 6px;
        border-radius: 35%;
    }

    .lists-for-footer h3 {
        text-align: center;
    }

    .lists-for-footer li {
        text-align: center;
        margin: 10px;
    }
}

@media(max-width:632px) {
    .footer-contents-main {
        margin-right: 200px;
        ;
        flex-direction: column;
    }

    .footer-contents-main img {
        width: 300px;
        height: 300px;
        margin: 6px;
        border-radius: 35%;
    }

    .lists-for-footer h3 {
        text-align: center;
    }

    .lists-for-footer li {
        text-align: center;
        margin: 10px;
    }

    .footer-main h1 {
        font-size: 25px;
    }
}

@media(max-width:524px) {
    .footer-contents-main {
        margin-right: 100px;
        ;
        flex-direction: column;
    }

    .footer-contents-main img {
        width: 300px;
        height: 300px;
        margin: 6px;
        border-radius: 35%;
    }

    .lists-for-footer h3 {
        text-align: center;
    }

    .lists-for-footer li {
        text-align: center;
        margin: 10px;
    }

    .footer-main h1 {
        font-size: 25px;
    }
}

@media(max-width:430px) {
    .footer-contents-main {
        margin-right: 50px;
        ;
        flex-direction: column;
    }

    .footer-contents-main img {
        width: 300px;
        height: 300px;
        margin: 6px;
        border-radius: 35%;
    }

    .lists-for-footer h3 {
        text-align: center;
    }

    .lists-for-footer li {
        text-align: center;
        margin: 10px;
    }

    .footer-main h1 {
        font-size: 22px;
    }
}

@media(max-width:380px) {
    .footer-contents-main {
        margin-right: 20px;
        ;
        flex-direction: column;
    }

    .footer-contents-main img {
        width: 250px;
        height: 250px;
        margin: 6px;
        border-radius: 35%;
    }

    .lists-for-footer h3 {
        text-align: center;
    }

    .lists-for-footer li {
        text-align: center;
        margin: 10px;
    }

    .footer-main h1 {
        font-size: 20px;
    }
}