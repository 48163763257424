.training {
   padding:1rem;
   /* background-image: linear-gradient(to right,#ff2526,#111);*/
}
.training img{
    width:500px;
}
.training h2{

    font-size: 55px;;
    color:#111;
}
.training p{
  
    color:#111;
    padding: 6px;
    width:80%;
    text-align: center;
    margin-left:40px;
    
}
.training h1{
    color:white;
   
    background-color:#ff2526;
    width:500px;
    text-align: center;
    margin-left:70px;
}
.corporate{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.corporate-one{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
@media(max-width:1029px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:500px;
        text-align: center;
        margin-left:200px;
    }
    .training h2{
         text-align: center;
        font-size: 45px;;
        color:#111;
    }
    .corporate{
        flex-direction: column;
    }
    .corporate-one{
    flex-direction: column;
    }


}
@media(max-width:937px){
    .training p{
  
        color:#111;
        padding: 6px;
        width:80%;
        text-align: center;
        margin-left:80px;
        
    }
}
@media(max-width:830px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:500px;
        text-align: center;
        margin-left:150px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:80%;
        text-align: center;
        margin-left:80px;
        
    }
    .training img{
        width:500px;
    }
}

@media(max-width:780px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:500px;
        text-align: center;
        margin-left:130px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:80%;
        text-align: center;
        margin-left:60px;
        
    }
    .training img{
        width:400px;
    }
}
@media(max-width:750px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:500px;
        text-align: center;
        margin-left:100px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:80%;
        text-align: center;
        margin-left:60px;
        
    }
    .training img{
        width:400px;
    }
}
@media(max-width:695px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:500px;
        text-align: center;
        margin-left:40px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:80%;
        text-align: center;
        margin-left:60px;
        
    }
    .training img{
        width:400px;
    }
}
@media(max-width:575px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:400px;
        font-size:24px;
        text-align: center;
        margin-left:40px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:80%;
        text-align: center;
        margin-left:40px;
        
    }
    .training img{
        width:300px;
    }
}
@media(max-width:500px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:350px;
        font-size:24px;
        text-align: center;
        margin-left:40px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:80%;
        text-align: center;
        margin-left:40px;
        
    }
    .training img{
        width:300px;
    }
}
@media(max-width:450px){
    .training h2{

        font-size: 25px;;
        color:#111;
    }
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:350px;
        font-size:24px;
        text-align: center;
        margin-left:40px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:100%;
        text-align: center;
        margin-left:5px;
        
    }
    .training img{
        width:300px;
    }
}

@media(max-width:420px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:350px;
        font-size:24px;
        text-align: center;
        margin-left:20px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:100%;
        text-align: center;
        margin-left:5px;
        
    }
    .training img{
        width:300px;
    }
}
@media(max-width:395px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:300px;
        font-size:20px;
        text-align: center;
        margin-left:10px;
    }
    .training p{
  
        color:#111;
        padding: 6px;
        width:100%;
        text-align: center;
        margin-left:5px;
        
    }
    .training img{
        width:300px;
    }
}
@media(max-width:335px){
    .training h1{
        color:white;
       
        background-color:#ff2526;
        width:300px;
        font-size:20px;
        text-align: center;
        margin-left:-2px;
    }
}