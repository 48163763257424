.service-background{
    position: relative;
    width:100%;
    height:100%;
}
.service-background img{
    width:100%;
    height:100vh;
  
}
.service-background .contents{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
}
.contents h1{
    color:white;
    text-align: center;
  /*  background:linear-gradient(90deg,#ff2526,#111);*/
    font-size:44px;
   /* -webkit-background-clip: text;*/
    /*-webkit-text-fill-color:transparent;*/
}
.contents p{
    color:white;

   /* background:linear-gradient(90deg,#ff2526,#111);*/
    font-size:30px;
  /*  -webkit-background-clip: text;*/
    /*-webkit-text-fill-color:transparent;*/
}
.lay-over{
    position: absolute;
    left:0;
    right:0;
    width:100%;
    height:100vh;
    background-color: rgb(0,0,0,.5); 
}
@media(max-width:1029px){
    .contents h1{
        color:white;
        text-align: center;
      /*  background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:40px;
       /* -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .contents p{
        color:white;
        width:100%;
       /* background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:26px;
      /*  -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
}
@media(max-width:790px){
    .service-background img{
        width:100%;
        height:70vh;
      
    }
    .contents h1{
        color:white;
        text-align: center;
      /*  background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:30px;
       /* -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .contents p{
        color:white;
        width:100%;
       /* background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:22px;
      /*  -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .lay-over{
        position: absolute;
        left:0;
        right:0;
        width:100%;
        height:70vh;
        background-color: rgb(0,0,0,.5); 
    }
}


@media(max-width:600px){
    .service-background img{
        width:100%;
        height:50vh;
      
    }
    .contents h1{
        color:white;
        text-align: center;
      /*  background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:30px;
       /* -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .contents p{
        color:white;
        width:100%;
       /* background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:22px;
      /*  -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .lay-over{
        position: absolute;
        left:0;
        right:0;
        width:100%;
        height:50vh;
        background-color: rgb(0,0,0,.5); 
    }
}


@media(max-width:485px){
    .service-background img{
        width:100%;
        height:40vh;
      
    }
    .contents h1{
        color:white;
        text-align: center;
      /*  background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:25px;
       /* -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .contents p{
        color:white;
        width:100%;
       /* background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:19px;
      /*  -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .lay-over{
        position: absolute;
        left:0;
        right:0;
        width:100%;
        height:40vh;
        background-color: rgb(0,0,0,.5); 
    }
}
@media(max-width:423px){
    .service-background img{
        width:100%;
        height:40vh;
      
    }
    .contents h1{
        color:white;
        text-align: center;
      /*  background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:20px;
        width:100%;
       /* -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .contents p{
        color:white;
        width:120%;
       /* background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:17px;
        font-weight: bold;
      /*  -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .lay-over{
        position: absolute;
        left:0;
        right:0;
        width:100%;
        height:40vh;
        background-color: rgb(0,0,0,.5); 
    }
}
@media(max-width:337px){
    .contents h1{
        color:white;
        text-align: center;
      /*  background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:19px;
        width:100%;
       /* -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
    .contents p{
        color:white;
        width:130%;
       /* background:linear-gradient(90deg,#ff2526,#111);*/
        font-size:17px;
        font-weight: bold;
      /*  -webkit-background-clip: text;*/
        /*-webkit-text-fill-color:transparent;*/
    }
}