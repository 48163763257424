.position-center img {
    width: 60px;
}

.position-center a {
    display: none;
    text-decoration: none;
    cursor: pointer;
}

.whatsApp {
    position: fixed;
    top: 90%;
    left: 80%;
    
}

/*@media(max-width:450px) {
    .position-center img {
        width: 60px;
    }

    .position-center a {
        display: block;
        text-decoration: none;
        cursor: pointer;
    }

    .whatsApp {
        position: fixed;
        top: 90%;
        left: 80%
    }
}
*/