.payment_cards{
    padding:2rem;
   
}
.payment_cards_three{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:5px;
}
.payment_cards_three img{
    width:100px;
    height:100px;
    border-radius:50%;
}
.igsce{
    width:400px;
    height:400px;
    background-image: linear-gradient(to bottom,#fff,#ff2526);
    padding:10px;
    text-align: center;
    border-radius: 10px;
    filter: drop-shadow(.75em .75em .75em);
}
.bgsce{
    width:400px;
    height:600px;
    background-image: linear-gradient(to right,#ff2526,#fff);
     text-align: center;
     border-radius: 10px;
     filter: drop-shadow(.75em .75em .75em);
     margin-top:6px;
}
.cambridge{
    width:400px;
    height:400px;
    background-image: linear-gradient(to bottom,#ff2526,#fff);
    padding:10px;
    text-align: center;
    border-radius: 10px;
    filter: drop-shadow(.75em .75em .75em);
}
.payment_cards button{
    width:200px;
    height:40px;
    outline:none;
    cursor: pointer;
    border-style: none;
    color:#fff;
    background-color: #111;
    border-radius: 8px;
    padding:10px;
    margin-left:20px;
    font-size:18px;
    transition: ease-in-out;
}
.payment_cards p{
    padding:6px;
    width:100%;
}
.payment_cards button:hover{
    background-color: red;
 
    transform: scale(1.2);
}
@media(max-width:1029px){
    .header-one{
        margin:10px;
        text-align: center;
    }
    .payment_cards_three{
        flex-direction: column;
        gap:15px;
    }
    .payment_cards_three img{
        width:100px;
        height:100px;
        border-radius:50%;
    }
    .igsce{
        width:400px;
        height:400px;
        background-image: linear-gradient(to bottom,#fff,#ff2526);
        padding:10px;
        text-align: center;
        border-radius: 10px;
        filter: drop-shadow(.75em .75em .75em);
    }
    .bgsce{
        width:400px;
        height:600px;
        background-image: linear-gradient(to right,#ff2526,#fff);
         text-align: center;
         border-radius: 10px;
         filter: drop-shadow(.75em .75em .75em);
         margin-top:6px;
    }
    .cambridge{
        width:400px;
        height:400px;
        background-image: linear-gradient(to bottom,#ff2526,#fff);
        padding:10px;
        text-align: center;
        border-radius: 10px;
        filter: drop-shadow(.75em .75em .75em);
    }
    .payment_cards button{
        width:200px;
        height:40px;
        outline:none;
        cursor: pointer;
        border-style: none;
        color:#fff;
        background-color: #111;
        border-radius: 8px;
        padding:10px;
        margin-left:20px;
        font-size:18px;
        transition: ease-in-out;
    }
    .payment_cards p{
        padding:6px;
        width:100%;
    }
    .payment_cards button:hover{
        background-color: red;
     
        transform: scale(1.2);
    }  
}

@media(max-width:450px){
    .header-one{
        margin:10px;
        text-align: left;
       text-transform: capitalize;
        font-size:25px;
    }
    .payment_cards_three{
        flex-direction: column;
        gap:15px;
    }
    .payment_cards_three img{
        width:60px;
        height:60px;
        border-radius:50%;
    }
    .igsce{
        width:290px;
        height:400px;
        background-image: linear-gradient(to bottom,#fff,#ff2526);
        padding:10px;
        text-align: center;
        border-radius: 10px;
        filter: drop-shadow(.75em .75em .75em);
    }
    .bgsce{
        width:290px;
        height:640px;
        background-image: linear-gradient(to right,#ff2526,#fff);
         text-align: center;
         border-radius: 10px;
         filter: drop-shadow(.75em .75em .75em);
         margin-top:6px;
    }
    .cambridge{
        width:290px;
        height:400px;
        background-image: linear-gradient(to bottom,#ff2526,#fff);
        padding:10px;
        text-align: center;
        border-radius: 10px;
        filter: drop-shadow(.75em .75em .75em);
    }
    .payment_cards button{
        width:180px;
        height:40px;
        outline:none;
        cursor: pointer;
        border-style: none;
        color:#fff;
        background-color: #111;
        border-radius: 8px;
        padding:10px;
        margin-left:10px;
        font-size:18px;
        transition: ease-in-out;
    }
    .payment_cards p{
        padding:6px;
        width:100%;
    }
    .payment_cards button:hover{
        background-color: red;
     
        transform: scale(1.2);
    }  
}