.physical-address h1{
   
    border-bottom: 6px solid #ff2526;
    width:270px;
    margin-left:280px;
}

.physical-address p{
    width:60%;
    margin-left:100px;
}
.physical{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.physical img{
    width:300px;
    height:300px;
    border-left:6px solid #ff2526;
}
.physical h3{
    text-align: center;
    font-size:25px;
}
.physical h1{
    text-align: center;
    font-size: 45px;
}
.physical button{
    width:240px;
    height:40px;
    outline:none;
    border-style:none;
    background-color: #ff2526;
    color:white;
    padding:10px;
    margin-left:150px;
    cursor: pointer;
}
.physical a{
    text-decoration: none;
}
@media(max-width:930px){
    .physical-address h1{
   
        border-bottom: 6px solid #ff2526;
        width:270px;
        margin-left:200px;
    }
   
    .physical-address p{
        width:75%;
        margin-left:30px;
    }
}
@media(max-width:850px){
    .physical-address h1{
   
        border-bottom: 6px solid #ff2526;
        width:270px;
        margin-left:240px;
    }
    
    .physical-address p{
        width:90%;
        margin-left:50px;
    }
    .physical{
       flex-direction: column;
    }
    .physical img{
        width:300px;
        height:300px;
        border-left:none;
        border-bottom:6px solid #ff2526;
    }
    .physical h3{
        text-align: center;
        font-size:25px;
    }
    .physical h1{
        text-align: center;
        font-size: 45px;
    }
    .physical button{
        width:240px;
        height:40px;
        outline:none;
        border-style:none;
        background-color: #ff2526;
        color:white;
        padding:10px;
        margin-left:150px;
        cursor: pointer;
    }
}@media(max-width:660px){
    .physical-address h1{
   
        border-bottom: 6px solid #ff2526;
        width:270px;
        margin-left:100px;
    }
    
    .physical-address p{
        width:90%;
        margin-left:30px;
    }
    .physical{
       flex-direction: column;
    }
    .physical img{
        width:300px;
        height:300px;
        border-left:none;
        border-bottom:6px solid #ff2526;
    }
    .physical h3{
        text-align: center;
        font-size:25px;
        margin-left:-25px;
    }
    .physical h1{
        text-align: center;
        font-size: 45px;
    }
    .physical button{
        width:240px;
        height:40px;
        outline:none;
        border-style:none;
        background-color: #ff2526;
        color:white;
        padding:10px;
        margin-left:120px;
        cursor: pointer;
    }
}
@media(max-width:400px){
    .physical-address h1{
   
     
        
        margin-left:50px;
    }
    
    .physical-address p{
        width:90%;
        margin-left:20px;
    }
    .physical{
       flex-direction: column;
    }
    .physical img{
        width:250px;
        height:250px;
        border-left:none;
     
    }
    .physical h3{
        text-align: center;
        font-size:25px;
        margin-left:-30px;
    }
    .physical h1{
        text-align: center;
        font-size: 45px;
    }
    .physical button{
        width:240px;
        height:40px;
        outline:none;
        border-style:none;
        background-color: #ff2526;
        color:white;
        padding:10px;
        margin-left:60px;
        cursor: pointer;
    }
}
@media(max-width:340px){
    .physical-address h1{
   
     
        
        margin-left:30px;
    }
    
    .physical-address p{
        width:90%;
        margin-left:10px;
    }
    .physical{
       flex-direction: column;
    }
    .physical img{
        width:250px;
        height:250px;
        border-left:none;
     
    }
    .physical h3{
        text-align: center;
        font-size:25px;
        margin-left:-35px;
    }
    .physical h1{
        text-align: center;
        font-size: 45px;
    }
    .physical button{
        width:240px;
        height:40px;
        outline:none;
        border-style:none;
        background-color: #ff2526;
        color:white;
        padding:10px;
        margin-left:40px;
        cursor: pointer;
    }
}