.services-offered{
    padding:1rem;
    
}
.red{
    border-bottom: 6px solid #ff2526;
}
.services-offered h2{
    text-align: center;
}
.services-images-text{
    display:flex;
    justify-content: space-around;
    align-items: center;
    
}
.services-offered p{
    width:95%;
}
.services-images-text img{
    width:300px;
    margin-left:60px;
}
@media(max-width:1029px){
 
    .services-images-text{
        padding-left:10px;
    }
    .services-offered p{
        width:80%;
        text-align: center;
       margin-left:30px;
    }
    .services-images-text img{
        width:200px;
        margin-left:60px;
    }
}
@media(max-width:845px){
    .services-offered h2{
        text-align: center;
    }
    .services-images-text{
        flex-direction: column;
        
        
    }
    .services-offered p{
        width:90%;
        text-align: center;
       margin-left:10px;
    }
    .services-images-text img{
        width:500px;
        margin-left:60px;
    }
    .red{
        border-bottom: 6px solid #ff2526;
        width:500px;
    }
}
@media(max-width:560px){
    .services-offered h2{
        text-align: center;
    }
    .services-images-text{
        flex-direction: column;
        
        
    }
    .services-offered p{
        width:90%;
        text-align: center;
       margin-left:5px;
    }
    .services-images-text img{
        width:400px;
        margin-left:40px;
    }
    .red{
        border-bottom: 6px solid #ff2526;
        width:500px;
    }
}
@media(max-width:430px){
    .services-images-text img{
        width:350px;
        margin-left:20px;
    }
}
@media(max-width:380px){
    .services-offered h2{
        text-align: center;
    }
    .services-images-text{
        flex-direction: column;
        
        
    }
    .services-offered p{
        width:90%;
        text-align: center;
       margin-left:8px;
       padding:5px;
    }
    .services-images-text img{
        width:300px;
        margin-left:20px;
    }
    .red{
        border-bottom: 6px solid #ff2526;
        width:500px;
    }
}