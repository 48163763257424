
.menu-navigation-logo-list-buttons{
    display:flex;
    justify-content: space-around;
    align-items: center;
    background-color:white;
    height:100px;
}
.logo{
    padding:0.3rem;
  
 
}

 a{
    text-decoration: none;
}
.logo img{
    margin-top:20px;
    width:200px;
    height:200px;
    
}
.nav-list{
    display:flex;
    justify-content: space-between;
    align-items: center;
 
}
.nav-list p{
  
    font-size:16px;
    color:#ff2526;
    margin-left:15px;
    font-weight: bold;
    filter: drop-shadow(.75em,.75em,.75em,.7em);
 
    border: 3px solid white;
}
.nav-list p:hover{
  
   border-bottom: 3px solid #ff2526;
}
.nav-list a{
    text-decoration: none;
    color:#111;
    cursor: pointer;
}
.main-navigation button{
    width:235px;
    height:45px;
    outline: none;
    border-style: none;
   
    background-image: linear-gradient(to right,#ff2526,#111);
    color:white;
    font-size:18px;
    margin:15px;
    cursor: pointer;
    font-weight: bold;
    padding:10px;
    border-radius: 12px;;
}
.buttons a{
    text-decoration: none;
}
.main-navigation button:hover{
    background-color:#ff2526;
}
.mobile-menu{
    display:none;
}
@media(max-width:855px){
    .menu-navigation-logo-list-buttons{
        display: none;
    }
    .mobile-menu{
        display:block;
      
     
    }
    .mobile-nav-button{
        display:flex;
        justify-content: space-between;
        align-items: center;
      height:100px;
     
    }
    .mobile-logo{
        padding:10px;
        
        
    }
    .mobile-logo img{
        margin-top:25px;
      
        width:160px;
        height:160px;
    }
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:76%;
         border-radius: 8px;
        background-color: white;
        z-index: 999;
    }
    .nav-list-mobile p{
        color:#ff2526;
        cursor: pointer;
        font-size:19px;
        text-align: center;
        padding:10px;
       
    }
}
@media(max-width:659px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:75%;
    
        background-color: white;
    }
}
@media(max-width:631px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:73%;
    
        background-color: white;
    }
}
@media(max-width:590px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:70%;
    
        background-color: white;
    }
}
@media(max-width:530px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:67%;
    
        background-color: white;
    }
}
@media(max-width:480px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:64%;
    
        background-color: white;
    }
}
@media(max-width:443px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:60%;
    
        background-color: white;
    }
}
@media(max-width:399px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:55%;
    
        background-color: white;
    }
}
@media(max-width:350px){
    .nav-list-mobile{
        position: absolute;
        top:15%;
        width:150px;
        height:170px;
        left:50%;
    
        background-color: white;
    }
}