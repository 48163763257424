   .contact-form-with-both-sides{
    padding:2rem;
    margin:1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;

    
}
.contact-form-with-both-sides h3{
    text-align:center;
    padding: 10px;;
    font-size:22px;
}
.contact-form-with-both-sides h2{
    color:white;
    text-align: center;
}
.sub-contact-form-div{
display:flex;
justify-content: space-between;
align-items: center;
background-color: white;
border:10px solid #ff2526;
filter: drop-shadow(.75em .75em .75em );
    border-radius:15px;
    padding:20px;
}
.the-detail-side-div{
    width:500px;
    height:500px;
    background-color: #ff2526;
    color:white
}
.the-detail-side-div p{
    width:100%;
    padding:5px;
    text-align:center;
    color:white;
}
.sub-contact-the-form-side{
    width:500px;
    height:500px;
    padding:10px;
    background-color: white;
    color:#ff2526; 
}
label{
    width:100px;
    padding:20px;
    color:#ff2526;
}
input{
    width:100%;
    padding:10px;
    border-radius:8px;
    margin:5px;
    color:#ff2526;
}
.sub-contact-the-form-side button{
    background-color: #ff2526;
    width:250px;
    height:50px;
    outline:none;
    border-style: none;
    cursor: pointer;
    border-radius: 8px;
    color: white;
    font-size:20px;
    margin-top:100px;
    margin-left:120px;
}
.sub-contact-the-form-side button:hover{
    background:magenta;
}
select{
    width:200px;
    background-color: white;
}
option{
    color:red;
    font-size:20px;
}
.sub-contact-the-form-side h3{
    color:#ff2526;
}
@media(max-width:1029px){
    .sub-contact-form-div{
        flex-direction: column;
     
         
     }
}
@media(max-width:900px){
    .sub-contact-form-div{
       flex-direction: column;
    
        
    }
}
@media(max-width:600px){
    .the-detail-side-div{
        width:400px;
        height:300px;
        background-color: #ff2526;
        color:white
    }
    .the-detail-side-div p{
        width:100%;
        padding:5px;
        text-align:center;
        color:white;
    }
    .sub-contact-the-form-side{
        width:400px;
        height:490px;
        padding:10px;
        background-color: white;
        color:#ff2526; 
    }
    .sub-contact-the-form-side button{
        background-color: #ff2526;
        width:250px;
        height:50px;
        outline:none;
        border-style: none;
        cursor: pointer;
        border-radius: 8px;
        color: white;
        font-size:20px;
        margin-left:60px;
    }
}
@media(max-width:500px){
    .the-detail-side-div{
        width:350px;
        height:300px;
        background-color: #ff2526;
        color:white
    }
    .the-detail-side-div p{
        width:100%;
        padding:5px;
        text-align:center;
        color:white;
    }
    .sub-contact-the-form-side{
        width:350px;
        height:450px;
        padding:10px;
        background-color: white;
        color:#ff2526; 
    }
    .sub-contact-the-form-side button{
        background-color: #ff2526;
        width:250px;
        height:50px;
        outline:none;
        border-style: none;
        cursor: pointer;
        border-radius: 8px;
        color: white;
        font-size:20px;
        margin-left:40px;
    }
    .margins{
        margin-left: 100px;
       display: none;  
    }
}
@media(max-width:455px){
    .the-detail-side-div{
        width:320px;
        height:300px;
        background-color: #ff2526;
        color:white
    }
    .the-detail-side-div p{
        width:100%;
        padding:5px;
        text-align:center;
        color:white;
    }
    .sub-contact-the-form-side{
        width:320px;
        height:450px;
        padding:10px;
        background-color: white;
        color:#ff2526; 
    }
    .sub-contact-the-form-side button{
        background-color: #ff2526;
        width:250px;
        height:50px;
        outline:none;
        border-style: none;
        cursor: pointer;
        border-radius: 8px;
        color: white;
        font-size:20px;
        margin-left:40px;
    }
    .margins{
        margin-left: 100px;
       display: none;  
    }
}


@media(max-width:455px){
    .the-detail-side-div{
        width:320px;
        height:300px;
        background-color: #ff2526;
        color:white
    }
    .the-detail-side-div p{
        width:100%;
        padding:5px;
        text-align:center;
        color:white;
    }
    .sub-contact-the-form-side{
        width:320px;
        height:450px;
        padding:10px;
        background-color: white;
        color:#ff2526; 
    }
    .sub-contact-the-form-side button{
        background-color: #ff2526;
        width:250px;
        height:50px;
        outline:none;
        border-style: none;
        cursor: pointer;
        border-radius: 8px;
        color: white;
        font-size:20px;
        margin-left:40px;
    }
    .margins{
        margin-left: 100px;
       display: none;  
    }
}

@media(max-width:399px){
    .the-detail-side-div{
        width:280px;
        height:300px;
        background-color: #ff2526;
        color:white
    }
    .the-detail-side-div p{
        width:100%;
        padding:5px;
        text-align:center;
        color:white;
    }
    .sub-contact-the-form-side{
        width:280px;
        height:450px;
        padding:10px;
        background-color: white;
        color:#ff2526; 
    }
    .sub-contact-the-form-side button{
        background-color: #ff2526;
        width:250px;
        height:50px;
        outline:none;
        border-style: none;
        cursor: pointer;
        border-radius: 8px;
        color: white;
        font-size:20px;
        margin-left:10px;
    }
    .margins{
        margin-left: 100px;
       display: none;  
    }
}
@media(max-width:366px){
    .the-detail-side-div{
        width:260px;
        height:300px;
        background-color: #ff2526;
        color:white
    }
    .the-detail-side-div p{
        width:100%;
        padding:5px;
        text-align:center;
        color:white;
    }
    .sub-contact-the-form-side{
        width:260px;
        height:450px;
        padding:10px;
        background-color: white;
        color:#ff2526; 
    }
    .sub-contact-the-form-side button{
        background-color: #ff2526;
        width:230px;
        height:50px;
        outline:none;
        border-style: none;
        cursor: pointer;
        border-radius: 8px;
        color: white;
        font-size:20px;
       margin-right:10px;
    }
    .margins{
        margin-left: 100px;
       display: none;  
    }
}

@media(max-width:345px){
    .the-detail-side-div{
        width:230px;
        height:300px;
        background-color: #ff2526;
        color:white
    }
    .the-detail-side-div p{
        width:100%;
        padding:5px;
        text-align:center;
        color:white;
    }
    .sub-contact-the-form-side{
        width:230px;
        height:450px;
        padding:10px;
        background-color: white;
        color:#ff2526; 
    }
    .sub-contact-the-form-side button{
        background-color: #ff2526;
        width:210px;
        height:50px;
        outline:none;
        border-style: none;
        cursor: pointer;
        border-radius: 8px;
        color: white;
        font-size:20px;
       margin-right:10px;
    }
    .margins{
        margin-left: 100px;
       display: none;  
    }
    .contact-form-with-both-sides h3{
        text-align:center;
       
        font-size:22px;
    }
    .contact-form-with-both-sides h2{
        color:white;
        
        text-align: center;
    }
}
