*{
  margin:0;
  box-sizing: border-box;
  padding:0;
}
body{
  overflow-x: hidden;
}
a{
  text-decoration: none;
}