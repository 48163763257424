.community{
    padding:1rem;

    background-image: linear-gradient(to right,#ff2526,#111);
}
.image-text-button{
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.community img{
    width:400px;
    margin-left:100px;
}
.community button{
    width:260px;
    height:45px;
    outline:none;
    border-style: none;
    background-color: #111;
    cursor: pointer;
    color:white;
    border-radius: 8px;
}
.community button:hover{
    background-color: magenta;
}
.community h2{
    color:white;
    font-size: 55px;;
    padding:5px;
}
.image-text-button p{
    color:white;
    padding:6px;
    width:70%;
}
.community h1{
    text-align: center;
    color:white;
    font-size: 40px;;
    padding:6px;
}
@media(max-width:1029px){
    .image-text-button p{
        color:white;
        padding:6px;
        width:100%;
    }
    .community h2{
        color:white;
        font-size: 35px;;
        padding:5px;
    }
}
@media(max-width:870px){
    .image-text-button{
       flex-direction: column;
    }
    .community img{
        width:400px;
        margin-left:100px;
    }
    .image-text-button p{
        color:white;
        padding:6px;
        width:70%;
    }
    .community h2{
        color:white;
        font-size: 45px;;
        padding:5px;
        font-weight: bold;
    }
    .words{
        margin-left:150px;
    }
}

@media(max-width:768px){
    .image-text-button{
       flex-direction: column;
    }
    .community img{
        width:400px;
        margin-left:50px;
    }
    .image-text-button p{
        color:white;
        padding:6px;
        width:90%;
    }
    .community h2{
        color:white;
        font-size: 45px;;
        padding:5px;
        font-weight: bold;
    }
    .words{
        margin-left:50px;
    }
}


@media(max-width:550px){
    .image-text-button{
       flex-direction: column;
    }
    .community img{
        width:300px;
        margin-left:30px;
    }
    .image-text-button p{
        color:white;
        padding:6px;
        width:90%;
    }
    .community h2{
        color:white;
        font-size: 45px;;
        padding:5px;
        font-weight: bold;
    }
    .words{
        margin-left:40px;
    }
}



@media(max-width:500px){
    .image-text-button{
       flex-direction: column;
    }
    .community img{
        width:300px;
        margin-left:30px;
    }
    .image-text-button p{
        color:white;
        padding:6px;
        width:90%;
    }
    .community h2{
        color:white;
        font-size: 35px;;
        padding:5px;
        font-weight: bold;
    }
    .words{
        margin-left:40px;
    }
}




@media(max-width:410px){
    .image-text-button{
       flex-direction: column;
    }
    .community img{
        width:250px;
        margin-left:20px;
    }
    .image-text-button p{
        color:white;
        padding:6px;
        width:100%;
    }
    .community h2{
        color:white;
        font-size: 30px;;
        padding:5px;
        font-weight: bold;
    }
    .words{
        margin-left:20px;
    }
    .community h1{
        text-align: center;
        color:white;
        font-size: 30px;;
        font-weight: bold;
        padding:6px;
       
    }
}





@media(max-width:345px){
    .image-text-button{
       flex-direction: column;
    }
    .community img{
        width:200px;
        margin-left:10px;
    }
    .image-text-button p{
        color:white;
        padding:6px;
        width:100%;
    }
    .community h2{
        color:white;
        font-size: 25px;;
        padding:5px;
        font-weight: bold;
    }
    .words{
        margin-left:10px;
    }
}